import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Route from './Routes/Routes';
import "./Styles/styles.scss";
import ReactGA from 'react-ga4'

ReactGA.initialize('G-C71QRC9813')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <Route/>
    </BrowserRouter>
  </React.StrictMode>
);

