import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from '../Containers/Home';
import Admin from '../Containers/Admin';
import Disclaimer from '../Components/Disclaimer';
import Privacy from '../Components/Privacy';
import TermsandConditions from '../Components/TermsAndConditions';
import Refund from '../Components/Reviews';
import Faq from '../Components/Faq';
import AdminHome from '../Containers/Admin/AdminHome';
import AdminService from '../Containers/Admin/Components/Services/AdminService';
import AdminNewService from '../Containers/Admin/Components/Services/newService';
import AdminPackage from '../Containers/Admin/Components/Packages/AdminPackage';
import AdminNewPackage from '../Containers/Admin/Components/Packages/NewPackage';
import AdminEditPackage from '../Containers/Admin/Components/Packages/AdminEditPackage';
import AdminEditService from '../Containers/Admin/Components/Services/editService';
import AdminLandingPage from '../Containers/Admin/Components/LandingPage';
import AdminUsers from '../Containers/Admin/Components/Users';
import AdminPayment from '../Containers/Admin/Components/Payment';
import ProtectedRoute from './ProtectedRoute'; // Protected Route
import Checkout from '../Components/Checkout';
import Success from '../Components/PaymentSuccess';
import Failure from '../Components/PaymentFail';
import SendLink from '../Containers/Admin/Components/SendLink';
import EmailTemplates from "../Containers/Admin/Components/Templates/index";
import EditEmailTemplate from "../Containers/Admin/Components/Templates/EditTemplate";
import AddTemplate from "../Containers/Admin/Components/Templates/AddTemplate";
import ReactGA from 'react-ga4'

const RoutesPage = () => {
    const location = useLocation()
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page:location.pathname + location.search, title:document.title || "Custom Title" });
    }, [location])

    return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/reviews" element={<Refund />} />
                <Route path="/terms-and-conditions" element={<TermsandConditions />} />
                <Route path="/faqs" element={<Faq />} />
                <Route path="/checkout-page/:id" element={<Checkout />} />
                <Route path="/payment-success" element={<Success />} />
                <Route path="/payment-failure" element={<Failure />} />

                {/* Admin Pages - Protected */}
                <Route
                    path="/ApiRaltHrAdmin"
                    element={<Admin />}
                />
                <Route
                    path="/ApiRaltHrAdmin-dashboard"
                    element={<ProtectedRoute element={<AdminHome />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-service"
                    element={<ProtectedRoute element={<AdminService />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-service-new"
                    element={<ProtectedRoute element={<AdminNewService />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-service-edit/:id"
                    element={<ProtectedRoute element={<AdminEditService />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-package"
                    element={<ProtectedRoute element={<AdminPackage />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-package-new"
                    element={<ProtectedRoute element={<AdminNewPackage />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-package-edit/:id"
                    element={<ProtectedRoute element={<AdminEditPackage />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-landing-page"
                    element={<ProtectedRoute element={<AdminLandingPage />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-users"
                    element={<ProtectedRoute element={<AdminUsers />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-payment"
                    element={<ProtectedRoute element={<AdminPayment />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-send-links/:id"
                    element={<ProtectedRoute element={<SendLink />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-email-templates"
                    element={<ProtectedRoute element={<EmailTemplates />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-edit-template/:id"
                    element={<ProtectedRoute element={<EditEmailTemplate />} requiredRole="admin" />}
                />
                <Route
                    path="/ApiRaltHrAdmin-add-template"
                    element={<ProtectedRoute element={<AddTemplate />} requiredRole="admin" />}
                />
            </Routes>
    );
}

export default RoutesPage;
